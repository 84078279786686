<template>
  <div :style="style" class="no-data">
    <img src="@/assets/noData.png" />
  </div>
</template>
<script>
export default {
  props: {
    style: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
<style lang="scss" scoped>
.no-data {
  text-align: center;
  img {
    width: 1.2rem;
    height: 1.41rem;
  }
}
</style>