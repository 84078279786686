<template>
  <div class="consumption-record">
    <nav-header></nav-header>
    <div class="mc_top">
      <span>{{ title }}</span>
    </div>
    <div class="mc_middle">
      <a-tabs
        class="nav-tabs"
        :default-active-key="consumeType[0].value"
        @change="callback"
      >
        <a-tab-pane
          v-for="item in consumeType"
          :key="item.value"
          :tab="item.label"
        >
          <template v-if="recordList && recordList.length">
            <div v-for="(item, index) in recordList" :key="index">
              <div class="cr_date">
                <span>{{ item.month }}</span> <span>{{ item.year }}</span>
              </div>
              <div
                class="cr_record"
                v-for="(record, reIndex) in item.list"
                :key="reIndex"
              >
                <div class="cr_logo">
                  <img :src="record.img" alt="" />
                </div>
                <div class="cr_type">
                  <div>
                    <div>{{ record.shopName }}</div>
                    <div>{{ record.date }}</div>
                  </div>
                  <div class="cr_mon">
                    <span class="cr_amount">{{ record.amount }}</span>
                    <img class="cr_coin" src="../../assets/coin.png" alt="" />
                    <span class="cr_icon"><a-icon type="right" /></span>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div style="text-align: center; margin: 1rem auto" v-else>
            <no-data :style="{marginTop:'65px'}" />
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>
<script>
import NavHeader from "@/components/NavHeader";
import NoData from "@/components/NoData";
export default {
  data() {
    return {
      title: "消费记录",
      consumeType: [
        { value: 0, label: "所有" },
        { value: 1, label: "在线订单" },
        { value: 2, label: "健康卡/礼" },
        { value: 3, label: "积分兑换" },
      ],
      recordList: [],
    };
  },
  components: {
    NavHeader,NoData
  },
  created() {},
  mounted() {
    this.getRecordList();
  },
  methods: {
    callback(key) {
      // console.log(key);
    },
    async getRecordList() {
      const userId = (this.$store.state.userInfo || {}).userId;
      const params = {
        cardNo: this.$route.params.cardNo,
        userId,
      };
      await this.$service
        .post("/mc/api/user/order/record", params)
        .then((res) => {
          this.recordList = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.consumption-record{
  margin-top: .5rem;
}
.mc_top {
  padding-left: .14rem;
  span {
    font-size: 0.26rem;
    font-weight: 600;
    color: #111111;
  }
}
.mc_middle {
  ::v-deep .ant-tabs-nav {
    .ant-tabs-tab {
      font-size: 0.12rem;
      color: #999999;
      margin: 0;
    }
    .ant-tabs-tab-active {
      font-size: 0.12rem;
      color: #111111;
    }
  }
  ::v-deep .ant-tabs-bar {
    margin: 0;
  }
  ::v-deep .ant-tabs-ink-bar {
    background-color: #00af66;
  }
  .cr_date {
    padding: 0.03rem 0 0.03rem 0.14rem;
    background: #e3e3e3;
    font-size: 0.1rem;
    font-weight: 500;
    color: #999999;
  }
  .cr_record {
    display: flex;
    height: 0.7rem;
    .cr_logo {
      flex: 1;
      margin: auto;
      text-align: center;
      img {
        width: 0.33rem;
      }
    }
    .cr_type {
      flex: 5;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #d8d8d8;

      .cr_mon {
        display: flex;
        align-items: center;
        .cr_amount {
          font-size: 0.18rem;
          font-weight: 500;
          color: #111111;
        }
        .cr_coin {
          width: 0.17rem;
          margin: 0 0.07rem 0 0.17rem;
        }
        .cr_icon {
          padding-right: 0.16rem;
        }
      }
    }
  }
}
</style>
